import * as React from "react"

import { _h1, _h2 } from "../../shared/headline.styles"
import { _Blockquote } from "../../shared/blockquote.styles"
import { _buttonBlock } from "../../shared/buttons"
import { Input } from "../../shared/inputs"
import { _formHint, _errorBox } from "../../shared/form/form.styles"

interface FormProps {
    isSubmitting: boolean
    errors: any
    handleChange: any
    handleSubmit: any
    labels: any
    isValid: boolean
    submitCount: number
    values: any
    status?: string
}

const formErrorMessage = (errors: any[]): JSX.Element => {
    return (
        <>
            <p>Please fix the following errors:</p>
            <ul>
                {Object.values(errors).map((error: string) => {
                    return <li key={error}>{error}</li>
                })}
            </ul>
        </>
    )
}

export const Form = (props: FormProps) => {
    const {
        isSubmitting,
        errors,
        handleChange,
        handleSubmit,
        labels,
        isValid,
        submitCount,
        values,
        status
    } = props

    return (
        <form>
            {!isValid && submitCount > 0 && <_errorBox>{formErrorMessage(props.errors)}</_errorBox>}

            <Input
                label="Email"
                type="email"
                name="businessEmail"
                placeholder="name@mail.com"
                onChange={handleChange}
                errors={errors.businessEmail}
                value={values.businessEmail}
            />

            <Input
                label={labels.name}
                type="text"
                name="name"
                placeholder="John Malkovitch"
                onChange={handleChange}
                errors={errors.name}
                value={values.name}
            />

            <Input
                label={labels.company}
                type="text"
                name="company"
                placeholder="Company name"
                onChange={handleChange}
                errors={errors.company}
                value={values.company}
            />

            <Input
                label={labels.message}
                type="textarea"
                name="message"
                onChange={handleChange}
                errors={errors.message}
                placeholder="Thoughts? Questions? Please share here."
                value={values.message}
            />

            <_buttonBlock onClick={handleSubmit} type="submit">
                {isSubmitting ? "Loading..." : "Contact us"}
            </_buttonBlock>

            {status === "success" && (
                <_Blockquote color={"var(--cool-green)"} style={{ textAlign: "center" }}>
                    Thanks! Your message was sent!
                </_Blockquote>
            )}
        </form>
    )
}
