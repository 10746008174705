import styled from "styled-components"

export const _formHint = styled.p`
    font-family: Nunito;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--slate-grey);
`

export const _errorBox = styled.div`
    background-color: var(--orange-red);
    border-radius: 5px;
    padding: 1em;

    font-family: Nunito;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;

    p,
    ul,
    li {
        color: white;
    }
`
