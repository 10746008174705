import * as React from "react"

import { _TextArea, _TextInput } from "../inputs"
import { _InputLabel, _InputWrapper } from "./input.styles"

interface TextareaProps {
    field: any
    form: any
}

export const TextareaFormikField: React.FC<TextareaProps> = ({ field, form, ...props }) => {
    return <_TextArea {...field} type="textarea" rows={5} {...props} />
}
