import * as React from "react"

import { _TextArea, _TextInputSecondary } from "../inputs"
import { _InputLabel, _InputWrapper } from "./input.styles"

interface TextinputProps {
  field: any
  form: any
}

export const TextinputSecondaryFormikField: React.FC<TextinputProps> = ({ field, ...props }) => {
  return <_TextInputSecondary {...field} {...props} />
}
