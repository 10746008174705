import styled from "styled-components"

export const _InputLabel = styled.label`
    font-family: Nunito;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--slate-grey);
`

export const _InputWrapper = styled.div`
    margin-bottom: 1em;
    margin-top: 1em;

    &.hasErrors {
        label {
            color: var(--orange-red) !important;
        }
    }
`
