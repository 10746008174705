import * as React from "react"
import styled from "styled-components"
import { Field } from "formik"

interface CheckboxProps {
    checked?: boolean
    label?: string
    className?: string | null
    value?: any
    name?: string
    errors?: object
    onChange?: any
    type?: "checkbox"
}

const _hiddenCheckbox = styled.input.attrs<CheckboxProps>({ type: "checkbox" })`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`

const _icon = styled.svg`
    fill: none;
    stroke: black;
    stroke-width: 2px;
`

const _styledCheckbox = styled.div<CheckboxProps>`
    display: inline-block;
    width: 24px;
    height: 24px;
    transition: all 150ms;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 0;

    ${_icon} {
        visibility: ${props => (props.checked ? "visible" : "hidden")};
    }
`

const _checkboxLabel = styled.label`
    span {
        font-family: Nunito;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: var(--slate-grey);
        padding-left: 10px;
    }

    &.hasErrors {
        span {
            color: var(--orange-red) !important;
        }
    }

    display: inline-block;
    margin-right: 1.5em;
    margin-bottom: 0.5em;
    cursor: pointer;
    user-select: none;
`

const _checkboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
`

export const Checkbox: React.FC<CheckboxProps> = ({ label, className, checked, errors, ...props }) => (
    <_checkboxLabel className={errors && Object.keys(errors).length > 0 ? "hasErrors" : ""}>
        <_checkboxContainer className={className ? className : ""}>
            <_hiddenCheckbox checked={checked} {...props} />
            <_styledCheckbox checked={checked}>
                <_icon viewBox="0 0 24 24">
                    <polyline points="20 6 9 17 4 12" />
                </_icon>
            </_styledCheckbox>
        </_checkboxContainer>

        <span>{label}</span>
    </_checkboxLabel>
)

type FieldCBProps = {
    field: { value: string[] }
    form: { setFieldValue: (a: string | undefined, b: string[]) => void }
}

export const CheckboxFormikField = (props: CheckboxProps) => {
    return (
        <Field name={props.name}>
            {({ field, form }: FieldCBProps) => (
                <Checkbox
                    {...props}
                    checked={field.value.includes(props.value)}
                    onChange={() => {
                        if (field.value.includes(props.value)) {
                            const nextValue = field.value.filter((value: any) => value !== props.value)
                            form.setFieldValue(props.name, nextValue)
                        } else {
                            const nextValue = field.value.concat(props.value)
                            form.setFieldValue(props.name, nextValue)
                        }
                    }}
                />
            )}
        </Field>
    )
}
