import * as React from "react"

import { _TextArea, _TextInput, SelectFormikField, TextareaFormikField, TextinputFormikField, TextinputSecondaryFormikField } from "../inputs"
import { Field } from "formik"
import { _InputLabel, _InputWrapper } from "./input.styles"

interface InputProps {
    label?: string
    type: string
    placeholder?: string
    name: string
    options?: any
    value?: any
    onChange?: any
    className?: string
    errors?: any[],
    secondary?: boolean
}

export const Input: React.FC<InputProps> = ({ label, type, placeholder, name, options, className, errors, secondary }) => {
    let input

    switch (type) {
        case "textarea":
            input = <Field name={name} component={TextareaFormikField} placeholder={placeholder} />
            break
        case "select":
            input = <Field name={name} component={SelectFormikField} options={options} />
            break
        default:
            input = <Field name={name} component={secondary ? TextinputFormikField : TextinputSecondaryFormikField} placeholder={placeholder} />
    }

    return (
        <_InputWrapper className={errors && errors.length > 0 ? "hasErrors" : className}>
            <_InputLabel>
                {label}
                {input}
            </_InputLabel>
        </_InputWrapper>
    )
}
