import * as yup from "yup"

export const validationSchema = yup.object().shape({
    businessEmail: yup
        .string()
        .email("E-mail is not valid!")
        .required("E-mail is required!"),
    name: yup.string().required("Name is required!"),
    company: yup.string().required("Company is required!")
})
