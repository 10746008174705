import * as React from "react"

import Select from "react-select"
import { FieldProps } from "formik"

type Option<T = string> = { label: string; value: T }
type ReactSelectProps = {
    options: Option[]
}

const customStyles = {
    control: (provided: any) => ({
        ...provided,
        borderRadius: "30px",
        backgroundColor: "#fff",
        border: "solid 1px #edeff2",
        height: "48px",
        padding: "0 7px"
    }),
    container: (provided: any) => ({
        ...provided,
        marginTop: "0.7em"
    }),
    placeholder: (provided: any) => ({
        ...provided,
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.5",
        letterSpacing: "0.1px",
        color: "#DEDEDE"
    })
}

export const SelectFormikField: React.FC<ReactSelectProps & FieldProps> = ({ options, field, form }) => (
    <Select
        options={options}
        name={field.name}
        value={options ? options.find((option: { value: any }) => option.value === field.value) : null}
        onChange={(option: Option) => form.setFieldValue(field.name, option.value)}
        onBlur={field.onBlur}
        styles={customStyles}
    />
)
