import styled from "styled-components"

export const _TextArea = styled.textarea.attrs(() => ({
    type: "text",
    size: "0.7em"
}))`
    border: none;
    font-size: 1em;
    width: 100%;
    margin: 0;
    margin-top: 0.5em;
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--slate-grey);
    border: solid 1px #edeff2;
    background-color: #ffffff;
    padding: 10px 15px 10px 15px;
    border-radius: 10px;

    ::placeholder {
        color: #dedede;
        opacity: 1;
    }

    &:focus::placeholder {
        color: #2e2e68;
    }
`
