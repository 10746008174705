import * as React from "react"

import { _h1, _h2 } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { Row, Col } from "react-flexbox-grid"
import styled from "styled-components"
import { _buttonBlock } from "../shared/buttons"
import { Formik } from "formik"
import { validationSchema, onSubmit, Form } from "./ContactFormParts"

const _ContactFormWrapper = styled.div`
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    padding: 0.5em;

    @media only screen and (min-width: 768px) {
        padding: 1em;
    }
`

interface ContactFormProps {
    title: JSX.Element | string
    labels: {
        name: string
        email: string
        company: string
        message: string
    }
}

const initialValues = {
    businessEmail: "",
    name: "",
    company: "",
    message: "",
}

export const ContactForm: React.FC<ContactFormProps> = props => {
    const { title } = props

    return (
        <Row>
            <Col lg={12} md={12} sm={12}>
                <_ContactFormWrapper>
                    <_h2 align="left" fontSize={24} id="formTitle">
                        {title}
                    </_h2>

                    <Formik
                        render={formikProps => <Form {...formikProps} {...props} />}
                        validationSchema={validationSchema}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                    />
                </_ContactFormWrapper>
            </Col>
        </Row>
    )
}
