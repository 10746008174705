import * as React from "react"

import { _TextArea, _TextInput } from "../inputs"
import { _InputLabel, _InputWrapper } from "./input.styles"

interface TextinputProps {
    field: any
    form: any
}

export const TextinputFormikField: React.FC<TextinputProps> = ({ field, form, ...props }) => {
    return <_TextInput {...field} {...props} />
}
