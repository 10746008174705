import * as React from "react"
import { Layout, _Section, HeadComponent } from "../components/shared"
import { graphql } from "gatsby"
import { Row, Col } from "react-flexbox-grid"
import { _h2 } from "../components/shared/headline.styles"
import { _Blockquote } from "../components/shared/blockquote.styles"
import { Hero, ContactForm } from "../components/contactPage"
import { prismicGetText, prismicGetRichText, prismicGetRichHeader, extractPrismicNode } from "../utils/prismicHelper"
import { getWindowDimensions, checkIfMobile } from "../utils"
import { PageWrapper } from "../utils/PageWrapper"

import Hexagon4 from "../images/v2/hexagon-4.svg"
import Hexagon5 from "../images/v2/hexagon-5.svg"
import styled from "styled-components"

const _svgImageLeft = styled.img`
    position: absolute;
    top: 170px;
    right: 88%;
    z-index: -1;
`

const _svgImageRight = styled.img`
    position: absolute;
    left: 105%;
    top: 225px;
    z-index: -1;
`

export const prismicQuery = graphql`
    {
        prismic {
            ...contactFragment
            ...colorfulLogoFragment
        }
    }
`

interface ContactPageProps {
    data: {
        prismic: {
            allContact_uss: {
                edges: any
            }

            allClient_logoss: {
                edges: any
            }
        }
    }
}

const ContactPage: React.FC<ContactPageProps> = ({ data }) => {
    // Required check for no data being returned
    const [isMobile, setIsMobile] = React.useState<boolean>(checkIfMobile(getWindowDimensions()))
    const doc = extractPrismicNode(data, "allContact_uss")
    if (!doc) return null

    const docClientLogos = extractPrismicNode(data, "allColorful_client_logos")
    if (!docClientLogos) return null

    React.useEffect(() => {
        function handleResize() {
            setIsMobile(checkIfMobile(getWindowDimensions()))
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    })

    return (
        <>
            <HeadComponent
                title="Contact | Radicle"
                keywords={prismicGetText(doc.seo_keywords_contact)}
                description={prismicGetText(doc.seo_description_contact)}
            />
            <PageWrapper>
                <Layout>
                    <Row>
                        <Col lg={6} sm={12}>
                            <Row style={{ position: "relative" }}>
                                {!isMobile && <_svgImageLeft src={Hexagon4} />}
                                <Col lg={10} md={12}>
                                    <_Section marginBottom={isMobile ? 1 : 5}>
                                        <Hero
                                            title={prismicGetRichHeader(doc.contact_title_v2)}
                                            lead={prismicGetRichText(doc.contact_lead)}
                                            subtitle={prismicGetText(doc.contact_title_2)}>
                                            {prismicGetRichText(doc.contact_lead_2)}
                                        </Hero>
                                    </_Section>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={6} sm={12}>
                            <_Section marginTop={isMobile ? 1 : 5}>
                                <ContactForm
                                    title={prismicGetRichHeader(doc.form_title_v2)}
                                    labels={{
                                        name: prismicGetText(doc.name),
                                        email: prismicGetText(doc.email),
                                        company: prismicGetText(doc.company),
                                        message: prismicGetText(doc.message)
                                    }}
                                />
                                {!isMobile && <_svgImageRight src={Hexagon5} />}
                            </_Section>
                        </Col>
                    </Row>
                </Layout>
            </PageWrapper>
        </>
    )
}

export default ContactPage
