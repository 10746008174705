import * as React from "react"

import { _h1, _h2 } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { Row, Col } from "react-flexbox-grid"

interface HeroProps {
    title: JSX.Element | string
    lead: JSX.Element | string
    subtitle: string
}

export const Hero: React.FC<HeroProps> = ({ title, lead, subtitle, children }) => {
    return (
        <Row>
            <Col lg={12} md={12} sm={12}>
                <_h1 align="left" style={{ marginTop: 0.2 + "em" }}>
                    {title}
                </_h1>
                <_Blockquote>{lead}</_Blockquote>

                <_h2 fontSize={24} align="left" style={{ marginTop: 1 + "em" }}>
                    {subtitle}
                </_h2>
                <_Blockquote>{children}</_Blockquote>
            </Col>
        </Row>
    )
}
