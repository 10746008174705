import styled from "styled-components"
import { Field } from "formik"

export const _TextInput = styled(Field).attrs(props => ({
    type: "text",
    size: props.size || "0.7em"
}))`
    border: none;
    font-size: 1em;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    margin: 0;
    margin-top: 0.5em;
    padding: ${props => props.size};

    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--slate-grey);
    border: solid 1px #edeff2;
    background-color: #ffffff;
    padding: 10px 15px 10px 15px;
    border-radius: 30px;

    ::placeholder {
        color: #dedede;
        opacity: 1;
    }

    &:focus::placeholder {
        color: #2e2e68;
    }
`

export const _TextInputSecondary = styled(_TextInput)`
    border-radius: 4px;
    background-blend-mode: multiply;
    background-color: rgba(9, 19, 33, 0.06);
    display: inline-block;
`;